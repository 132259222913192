// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-excerpt-download-form-js": () => import("./../../../src/pages/excerpt-download-form.js" /* webpackChunkName: "component---src-pages-excerpt-download-form-js" */),
  "component---src-pages-excerpt-thank-you-js": () => import("./../../../src/pages/excerpt-thank-you.js" /* webpackChunkName: "component---src-pages-excerpt-thank-you-js" */),
  "component---src-pages-guide-download-form-js": () => import("./../../../src/pages/guide-download-form.js" /* webpackChunkName: "component---src-pages-guide-download-form-js" */),
  "component---src-pages-guide-thank-you-js": () => import("./../../../src/pages/guide-thank-you.js" /* webpackChunkName: "component---src-pages-guide-thank-you-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/NewsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

